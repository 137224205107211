import openSocket from "socket.io-client";
import { getBackendUrl } from "../config";
import api from "./api";

export async function refreshToken() {
  const { data } = await api.post("/auth/refresh_token");
  if (data) {
    localStorage.setItem("token", JSON.stringify(data.token));
    return data.token;
  }
  return null;
}

function connectToSocket() {
  const token = localStorage.getItem("token");
  const socket = openSocket(getBackendUrl(), {
    pingIntenal: 10000,
    pingTimeout: 5000,
    query: {
      token: JSON.parse(token),
    },
    //autoConnect: true,
    //reconnection: true,
    //reconnectionAttempts: Infinity,
    //reconnectionDelay: 5000,
    //reconnectionDelayMax: 300000,
  });

  socket.on("disconnect", async (reason) => {
    console.log("Disconnected from socket");
    console.log(reason);
    if (reason === 'io server disconnect') {
      token = await refreshToken();
      if (token) {
        socket = openSocket(getBackendUrl(), {
          pingInterval: 10000,
          pingTimeout: 5000,
          query: { token },
        });
      } else {
        console.error("Falha ao renovar o token");
      }
    }
  });

  /*socket.on("disconnect", (reason) => {
    console.log("Disconnected from socket");
    console.log(reason);
  });

  socket.on("connection", () => {
    console.log("Connected to socket");
  });*/

  return socket;
}

export default connectToSocket;
